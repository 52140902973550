const { default: images } = require("../../assets/images");
const { default: speakers } = require("../../assets/images/speakers");

const speakerList = [
    {
        name: "ראובן (רובי) ריבלין",
        title: "נשיא המדינה",
        image: speakers[1]
    },
    {
        name: "שלמה ארצי",
        title: "זמר ויוצר",
        image: speakers[2]
    },
    {
        name:  "דידי הררי",
        title: "מנחה ושדרן",
        image: speakers[3]
    },
    {
        name: "נועה קולר",
        title: "שחקנית ומחזאית",
        image: speakers[4]
    },
    {
        name:  "פרופ' גליה רהב" ,
        title:  "מנהלת היחידה למחלות זיהומיות בבית החולים שיבא תל השומר" ,
        image: speakers[5]
    },
    {
        name: "פרופ' נחמן אש",
        title:   '"ראש תכנית "מגן ישראל',
        image: speakers[6]
    },
    {
        name: "פרופ' רוני גמזו" ,
        title: 'מנהל בית החולים איכילוב, ראש תוכנית "מגן ישראל" לשעבר',
        image: speakers[7]
    },
    {
        name: "משה בר סימן טוב" ,
        title:   "מנכ'ל משרד הבריאות לשעבר" ,
        image: speakers[8]
    },
    {
        name: "איימן סייף",
        title:'ראש מטה קורונה בחברה הערבית במגן ישראל' ,
        image: speakers[47]
    },
    {
        name: "אסף גרניט" ,
        title:"שף" ,
        image: speakers[9]
    },
    {
        name: "שלומי קוריאט" ,
        title: "שחקן וסטנדאפיסט",
        image: speakers[10]
    },
    {
        name: "עברי לידר" ,
        title:  "זמר",
        image: speakers[11]
    },
    {
        name: "גילה אלמגור" ,
        title: "שחקנית תיאטרון הבימה" ,
        image: speakers[12]
    },
    {
        name:   "נדב איל",
        title:  "",
        image: speakers[13]
    },
    {
        name: "יוסי יהושוע" ,
        title: "" ,
        image: speakers[14]
    },
    {
        name:  "שמעון שיפר",
        title: ""  ,
        image: speakers[15]
    },
    {
        name: "נחום ברנע" ,
        title:"" ,
        image: speakers[16]
    },
    {
        name: "רותי רוסו" , 
        title: "שפית ועיתונאית" ,
        image: speakers[17]
    },
    {
        name:"סמדר שיר" ,
        title: "סופרת ועיתונאית",
        image: speakers[18]
    },
    {
        name: "יפה בן דויד",
        title:'מזכ"לית הסתדרות המורים',
        image: speakers[19]
    },
    {
        name: "יצחק (בוז'י) הרצוג",
        title:'יו"ר הסוכנות היהודית',
        image: speakers[20]
    },
    {
        name:  "רז שכניק",
        title: "" ,
        image: speakers[21]
    },
    {
        name: "שחר צוברי" ,
        title: "גולש רוח ומדליסט אולימפי" ,
        image: speakers[22]
    },
    {
        name:  "שגיא מוקי" ,
        title: "אלוף העולם בג'ודו לשנת 2019-2020" ,
        image: speakers[23]
    },
    {
        name:  "אירה ויגדורצ'יק",
        title: "המאמנת הראשית של נבחרת ישראל בהתעמלות אמנותית" ,
        image: speakers[24]
    },
    {
        name:  "רענן שקד",
        title:   "",
        image: speakers[25]
    },
    {
        name: "מורן אזולאי" ,
        title: "",
        image: speakers[26]
    },
    {
        name: "דנה ספקטור" , 
        title: "" ,
        image: speakers[27]
    },
    {
        name: "יובל קרני",
        title:"",
        image: speakers[28]
    },
    {
        name: "חיים ביבס",
        title:'יו"ר מרכז השלטון המקומי',
        image: speakers[29]
    },
    {
        name: "ארנון בר דוד",
        title:"יו”ר ההסתדרות",
        image: speakers[30]
    },
    {
        name: "פרופ' ציון חגי" ,
        title: 'יו"ר ההסתדרות הרפואית' ,
        image: speakers[31]
    },
    {
        name: "בן דרור ימיני" ,
        title: "" ,
        image: speakers[32]
    },
    {
        name: 'ד"ר רונן ברגמן'  ,
        title: "" ,
        image: speakers[33]
    },
    {
        name: "אטילה שומפלבי" ,
        title:  "",
        image: speakers[34]
    },
    {
        name: 'עו"ד רוית דום עיני' ,
        title:  'מנכ"לית קבוצת עמל' ,
        image: speakers[35]
    },
    {
        name:"דני שביט"  ,
        title:'מנכ"ל קבוצת לובינסקי' ,
        image: speakers[36]
    },
    {
        name: "מתניהו אנגלמן" , 
        title:"מבקר המדינה"  ,
        image: speakers[37]
    },
    {
        name: "אדיר ינקו",
        title:"ynet כתב",
        image: speakers[38]
    },
    {
        name: "הדר גיל-עד",
        title:"",
        image: speakers[39]
    },
    {
        name: "עמיחי אתאל",
        title:"",
        image: speakers[40]
    },
    {
        name: "עידו גרינברג" ,
        title: " יוצר #משפטאחדביום" ,
        image: speakers[41]
    },
    {
        name:  `ד"ר ג'רמי פוגל`,
        title:"מרצה לפילוסופיה יהודית ומשורר"  ,
        image: speakers[42]
    },
    {
        name:   "יהודה משי זהב",
        title: 'יו"ר זק"א' ,
        image: speakers[43]
    },
    {
        name:   "משפחת טרסוב",
        title:  'כוכבי רשת',
        image: speakers[44]
    },
    {
        name: "מירב בטיטו" ,
        title:  '' ,
        image: speakers[45]
    },
    {
        name:  "האלוף אהרון חליוה",
        title: "ראש אגף המבצעים",
        image: speakers[46]
    },
    {
        name:  "בראאה גלטא",
        title:  'מתנדבת מד"א מהשירות הלאומי ומצטיינת נשיא המדינה',
        image: speakers[48]
    },
]

export default speakerList