import speaker1 from "./speaker1.png";
import speaker2 from "./speaker2.png";
import speaker3 from "./speaker3.png";
import speaker4 from "./speaker4.png";
import speaker5 from "./speaker5.png";
import speaker6 from "./speaker6.png";
import speaker7 from "./speaker7.png";
import speaker8 from "./speaker8.png";
import speaker9 from "./speaker9.png";
import speaker10 from "./speaker10.png";
import speaker11 from "./speaker11.png";
import speaker12 from "./speaker12.png";
import speaker13 from "./speaker13.png";
import speaker14 from "./speaker14.png";
import speaker15 from "./speaker15.png";
import speaker16 from "./speaker16.png";
import speaker17 from "./speaker17.png";
import speaker18 from "./speaker18.png";
import speaker19 from "./speaker19.png";
import speaker20 from "./speaker20.png";
import speaker21 from "./speaker21.png";
import speaker22 from "./speaker22.png";
import speaker23 from "./speaker23.png";
import speaker24 from "./speaker24.png";
import speaker25 from "./speaker25.png";
import speaker26 from "./speaker26.png";
import speaker27 from "./speaker27.png";
import speaker28 from "./speaker28.png";
import speaker29 from "./speaker29.png";
import speaker30 from "./speaker30.png";
import speaker31 from "./speaker31.png";
import speaker32 from "./speaker32.png";
import speaker33 from "./speaker33.png";
import speaker34 from "./speaker34.png";
import speaker35 from "./speaker35.png";
import speaker36 from "./speaker36.png";
import speaker37 from "./speaker37.png";
import speaker38 from "./speaker38.png";
import speaker39 from "./speaker39.png";
import speaker40 from "./speaker40.png";
import speaker41 from "./speaker41.png";
import speaker42 from "./speaker42.png";
import speaker43 from "./speaker43.png";
import speaker44 from "./speaker44.png";
import speaker45 from "./speaker45.png";
import speaker46 from "./speaker46.png";
import speaker47 from "./speaker47.png";
import speaker48 from "./speaker48.png";
import mainSpeaker1 from "./host1.png";
import mainSpeaker2 from "./host2.png";

export const MainSpeaker1 = mainSpeaker1;
export const Mainspeaker2 = mainSpeaker2;
const speaker0 = "";

const speakers = [
  speaker0,
  speaker1,
  speaker2,
  speaker3,
  speaker4,
  speaker5,
  speaker6,
  speaker7,
  speaker8,
  speaker9,
  speaker10,
  speaker11,
  speaker12,
  speaker13,
  speaker14,
  speaker15,
  speaker16,
  speaker17,
  speaker18,
  speaker19,
  speaker20,
  speaker21,
  speaker22,
  speaker23,
  speaker24,
  speaker25,
  speaker26,
  speaker27,
  speaker28,
  speaker29,
  speaker30,
  speaker31,
  speaker32,
  speaker33,
  speaker34,
  speaker35,
  speaker36,
  speaker37,
  speaker38,
  speaker39,
  speaker40,
  speaker41,
  speaker42,
  speaker43,
  speaker44,
  speaker45,
  speaker46,
  speaker47,
  speaker48,
];

export default speakers;
