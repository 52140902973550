import React from "react";
import styles from "./MainSpeakerCard.module.scss";

function MainSpeakerCard({ image, name, title }) {
  return (
    <div className={styles.speakerCard}>
      <div className={styles.imageContainer}>
        <img className={styles.image} src={image} alt="" />
      </div>
      <h2 className={styles.name}>{name}</h2>
      <h2 className={styles.title}>{title}</h2>
    </div>
  );
}

export default MainSpeakerCard;
