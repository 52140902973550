import React from "react";
import styles from "./Footer.module.scss";
import images from "../../assets/images";
import usePopup from "../../hooks/usePopup";
import partners from "../../assets/images/partners";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import gtag from "../../gtag";
import sponsors from "../../assets/images/sponsors";

function Footer() {
  const [popupActive, setPopupActive] = usePopup();
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <hr className={styles.line} />
        <h1
          className={styles.title}
          onClick={() => {
            setPopupActive(!popupActive);
            gtag.programClick();
          }}
        >
          <span>
            <img className="inline-image" src={images.leftArrow} alt="" />
          </span>
          לתוכנית הועידה לחץ כאן
        </h1>
        <hr className={styles.line} />
      </div>
      <p className={styles.description}>
        צילומים: דובר צה"ל,דניאל קמינסקי, אופיר יצחק, אבי מועלם, אורן אהרוני,
        רון קדמי, אילן בשור, אלכס קולומויסקי, דנה קופל, הילה סמו, אבי אוחיון,
        ראובן קפוצ׳ינסקי, טל שחר, רותי רוסו, יריב כץ, שירה זיו, רוני כהן, עוז
        מועלם, יאיר שגיא, יוסי דוס סנטוס, אוהד רומנו, אפי שריר, אביגיל עוזי,
        יובל חן, גיל יוחנן, צביקה טישלר, אוריאל כהן, אביגיל עוזי, תומריקו, רונית
        כהן, אור דנון, מילה פרלשטיין, איתמר רותם, צביקה טישלר, עמית שאל, אוריאל
        כהן, ירון ברנר, מתן צורי, עמית שאבי
      </p>
      <div className={styles.w100}>
        <hr className={styles.line} />
      </div>
      <div className={styles.partnersContainer}>
        {partners.map((item) => (
          <img className={styles.partners} src={item} alt="" />
        ))}
      </div>
      <div className={styles.partnersContainer}>
        {sponsors.map((item) => (
          <img className={styles.sponsors} src={item} alt="" />
        ))}
      </div>
      <h2 className={styles.endCredits}>אוצרת תוכן: יעל אפטר</h2>
    </div>
  );
}

export default Footer;
