import React, { useEffect, useState } from "react";
import drive from "drive-db";
import images from "../../assets/images";
import Footer from "../Footer";
import ListHeader from "../ListHeader";
import MainSpeakerCard from "../MainSpeakerCard";
import SpeakerCard from "../SpeakerCard";
import styles from "./SpeakersSection.module.scss";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import speakers, {
  MainSpeaker1,
  Mainspeaker2,
} from "../../assets/images/speakers";
import axios from "axios";
import { storage } from "../../firebase";
import speakerList from "./speakerList";

function SpeakersSection() {
  const [data, setData] = useState([]);
  const JSONFileRef = storage.refFromURL(
    "gs://ynet-people.appspot.com/conf_22022021/speakers.json"
  );

  useEffect(() => {
    JSONFileRef.getDownloadURL().then((url) => {
      axios.get(url).then((res) => {
        setData(res.data);
        console.log(res.data);
      });
    });
  }, []);

  return (
    <section id="speakerSection">
      <div
        className={styles.main}
        style={{
          backgroundImage: `url(${images.longBackground})`,
        }}
      >
        <div className={styles.container}>
          <div className={styles.mainSpeakerCardContainer}>
            <MainSpeakerCard
              image={MainSpeaker1}
              name="אביב אלוש"
              title="מנחה"
            />
            <MainSpeakerCard
              image={Mainspeaker2}
              name={"נלי תגר"}
              title={"מנחה"}
            />
          </div>
          <ListHeader />
          <div className={styles.speakerCardContainer}>
            {
              speakerList.map((item, index) => {
                return (
                  <SpeakerCard
                    key={index}
                    name={item.name}
                    image={item.image}
                    title={item.title}
                  />
                );
              })
              // data.length > 0 &&
              //   data.map((item, index) => {
              //     return (
              //       <SpeakerCard
              //         key={index}
              //         name={item.name}
              //         image={item.image}
              //         title={item.title}
              //       />
              //     );
              //   })
            }
          </div>
          <Footer />
        </div>
      </div>
    </section>
  );
  console.log(data);
}

export default SpeakersSection;
