const gtag = {
  shareEvent: (social) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'GA_events',
      Category: 'Social',
      Action: 'Share From Site Head',
      Label: social,
    })
  },
  programClick: () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'GA_events',
      Category: 'Navigation',
      Action: 'Click to Conference program',
    })
  },
}

export default gtag
