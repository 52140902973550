import { PopupProvider } from './hooks/PopupContext';
import LandingPage from './Pages/LandingPage/index'

function App() {
  return (
    <div className="App">
      <PopupProvider>
      <LandingPage/>
      </PopupProvider>
     
    </div>
  );
}

export default App;
