import headerBackground from "./header_background.jpg";
import header from "./header.png";
import longBackground from "./long_background.svg";
import facebookIcon from './facebook_icon.svg'
import wheelChairIcon from './wheelchair_icon.svg'
import whatsappIcon from './whatsapp_icon.svg'
import speaker1 from './speaker1.jpg'
import speaker2 from './speaker2.jpg'
import ynetIcon from './ynet_icon.png'
import redBlueIcon from './red_blue_icon.png'
import downIcon from './down_icon.svg'
import partners from './partners.png'
import partner1 from './partner1.png'
import partner2 from './partner2.png'
import leftArrow from './left_arrow_icon.svg'
import ynetInline from './ynet_inline.svg'
import closeIcon from './close_icon.svg'
import popup_d from './popup_d.jpg'
import popup_m from './popup_m.jpg'

const images = {
  header,
  headerBackground,
  longBackground,
  facebookIcon,
  wheelChairIcon,
  whatsappIcon,
  speaker1,
  speaker2,
  ynetIcon,
  redBlueIcon,
  downIcon,
  partners,
  partner1,
  partner2,
  leftArrow,
  ynetInline,
  closeIcon,
  popup_d,
  popup_m
};

export default images;
