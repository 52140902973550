import React from "react";
import Header from "../../components/Header";
import Navbar from "../../components/Navbar";
import SpeakersSection from "../../components/SpeakersSection";
import { BrowserRouter as Router } from "react-router-dom";
import Popup from "../../components/Popup";
import "./LandingPage.scss";
import { PopupProvider } from "../../hooks/PopupContext";
import usePopup from "../../hooks/usePopup";

export default function LandingPage() {
  const [active] = usePopup();
  return (
    <div>
      <Router>
        <Navbar />
        <Header />
        <SpeakersSection />
        <Popup active={active} />
      </Router>
    </div>
  );
}
