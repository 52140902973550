import React, { useState, createContext } from "react";

export const PopupContext = createContext();

export function PopupProvider(props) {
  const [active, setActive] = useState(false);
  return (
    <PopupContext.Provider value={[active, setActive]}>
      {props.children}
    </PopupContext.Provider>
  );
}
