import React, { useEffect, useRef } from "react";
import images from "../../assets/images";
import styles from "./Header.module.scss";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import { findDOMNode } from "react-dom";

function Header() {
  const refVideo = useRef(null);
  const videoMobile =
    "https://player.vimeo.com/external/510181568.sd.mp4?s=14e0483ce465c2216c44d1363143177775290ff4&profile_id=164 ";
  const videoDesktop =
    "https://player.vimeo.com/external/510181649.hd.mp4?s=b0bbcc7a2bfd0cf37a3a515f372e2d6684f656e1&profile_id=175";
  const isMobile = window.innerHeight <= 640 ? true : false;
  const scrollToSpeakers = () => {
    document
      .getElementById("speakerSection")
      .scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section>
      <div className={styles.main}>
        <div
          dangerouslySetInnerHTML={{
            __html: `
        <video
        id="video_background"
          loop
          muted
          autoplay
          playsinline
          src="${isMobile ? videoMobile : videoDesktop}" 
          style="position: absolute;
          z-index: -1;
          object-fit: cover;
          height: 100%;
          width: 177.77777778vh; 
          min-width: 100%;
          min-height: 56.25vw;
          left: 0; 
          right: 0; 
          margin-left: auto; 
          margin-right: auto;"
        />,
      `,
          }}
        />
        <Zoom>
          <img className={styles.headerImage} src={images.header} alt="" />
        </Zoom>
        <Fade up delay={500}>
          <p className={styles.headerText}>
            <span className={styles.bold}>ynet</span>-ידיעות אחרונות ו
          </p>
          <p className={styles.headerText}>מביאים אל מרכז הבמה את </p>
          <p className={styles.headerText}>האנשים שעשו לנו את השנה</p>{" "}
          <h1 className={styles.headerDate}>22.2.2021</h1>{" "}
          <p className={styles.headerText2}>
            <span>
              <img
                className={styles.ynetInline}
                src={images.ynetInline}
                alt=""
              />
            </span>{" "}
            שידור חי ב
          </p>
          <p className={styles.headerText2}>משלל מוקדים ברחבי הארץ</p>
        </Fade>

        <img
          onClick={() => scrollToSpeakers()}
          className={styles.downIcon}
          src={images.downIcon}
          alt=""
        />
      </div>
    </section>
  );
}

export default Header;
