import React from "react";
import { act } from "react-dom/test-utils";
import images from "../../assets/images";
import useResponsiveQuery from "../../assets/utils/useResponsiveQuery";
import usePopup from "../../hooks/usePopup";
import styles from "./Popup.module.scss";

const Popup = ({ active }) => {
  const [popupActive, setPopupActive] = usePopup();
  const isMobile = useResponsiveQuery();
  return (
    <div
      className={styles.overlay}
      style={{
        visibility: active ? "visible" : "hidden",
        opacity: active ? 1 : 0,
      }}
    >
      <div className={styles.popup}>
        <img
          onClick={() => setPopupActive(false)}
          className={styles.closeIcon}
          src={images.closeIcon}
          alt=""
          style={{ pointerEvents: "all" }}
        />

        <img
          className={styles.agenda}
          src={isMobile ? images.popup_m : images.popup_d}
          alt=""
        />
      </div>
    </div>
  );
};

export default Popup;
