import firebase from 'firebase'
import 'firebase/storage'

export const app = firebase.initializeApp({
  "projectId": "ynet-people",
  "appId": "1:834165911743:web:c04923f2e3a48417f9960a",
  "storageBucket": "ynet-people.appspot.com",
  "locationId": "europe-west",
  "apiKey": "AIzaSyBZcxmm1Qah3YPlB-GWXYbQZLrm26y0umQ",
  "authDomain": "ynet-people.firebaseapp.com",
  "messagingSenderId": "834165911743"
});

export const storage = firebase.storage()

