import React from "react";
import images from "../../assets/images";
import { Link } from "react-router-dom";
import styles from "./Navbar.module.scss";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";
import ScriptTag from "react-script-tag";
import gtag from "../../gtag";
import useResponsiveQuery from "../../assets/utils/useResponsiveQuery";

const SHARE_CONSTANTS = {
  WHATSAPP: "whatsapp",
  FACEBOOK: "facebook",
};

const CAN_USE_DOM = !!(
  typeof window !== "undefined" &&
  window.document &&
  window.document.createElement
);

function Navbar() {
  const isMobile = useResponsiveQuery();
  const share = (type) => {
    const url = `https://PeopleConference.ynet.co.il`;
    const img = CAN_USE_DOM
      ? document
          .querySelector('meta[property="og:image"]')
          .getAttribute("content")
      : null;
    const title = CAN_USE_DOM
      ? document
          .querySelector('meta[property="og:title"]')
          .getAttribute("content")
      : null;
    const description = CAN_USE_DOM
      ? document
          .querySelector('meta[property="og:description"]')
          .getAttribute("content")
      : null;

    if (type === SHARE_CONSTANTS.FACEBOOK) {
      const fb = "https://www.facebook.com/sharer.php?u=";

      const shareURL = `${fb}${encodeURIComponent(
        `${url}?title=${title}&desc=${description}&img=${img}`
      )}`;
      window.open(shareURL, "sharer", "width=555,height=600");
    }

    if (type === SHARE_CONSTANTS.WHATSAPP) {
      if (!isMobile) {
        window.open(
          `https://web.whatsapp.com/send?v=1&text= ${encodeURIComponent(
            `${title}${description}${url}`
          )} `,
          "_blank"
        );
      } else {
        window.location.href = `whatsapp://send?text=${encodeURIComponent(
          `${title}\n${description}\n${url}`
        )}`;
      }
    }
  };

  const onShareClick = (e) => {
    const { id } = e.currentTarget.dataset;

    share(id);
    gtag.shareEvent(id);
  };

  const socialIcons = [
    images.wheelChairIcon,
    images.facebookIcon,
    images.whatsappIcon,
  ];
  return (
    <nav>
      <div className={styles.main}>
        <div className={styles.socialContainer}>
          <a
            onClick={() => {
              window.document.getElementById("INDmenu-btn").click();
            }}
          >
            <img
              src={images.wheelChairIcon}
              className={`${styles.socialButtons} negishut_button`}
              alt=""
            />
          </a>
          <a data-id={SHARE_CONSTANTS.FACEBOOK} onClick={onShareClick}>
            <img
              src={images.facebookIcon}
              className={styles.socialButtons}
              alt=""
            />
          </a>
          <a data-id={SHARE_CONSTANTS.WHATSAPP} onClick={onShareClick}>
            <img
              src={images.whatsappIcon}
              className={styles.socialButtons}
              alt=""
            />
          </a>
        </div>
        <div
          className={styles.logoContainer}
          style={{
            display: "flex",
            flexDirection: "row",
            paddingRight: "12px",
          }}
        >
          <Link>
            <img className={styles.logo} src={images.ynetIcon} />
          </Link>
          <Link>
            <img className={styles.logo} src={images.redBlueIcon} alt="" />
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
